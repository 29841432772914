<template>
	<div class="main-content">
		<ktv-breadcrumb :title="`${opsiDisplay}`+' Form'" page="Form" folder="Customer" />
		<div>
			<ktv-back @click="backToList()" />
			<b-row class="mt-3">
				<b-col cols="8">
					<ktv-accor title="Customer Data" :footer="opsiDisplay !== 'View'">
						<template #content>
							<vcl-list
								v-show="loading"
								class="mt-4"
							/>
							<vcl-list
								v-show="loading"
								class="mt-4"
							/>
							<b-row v-show="!loading" class="tab-content">
								<b-col class="mr-3">
									<b-form-group class="mt-2" label="Entity ID">
										<b-form-input v-model="form.EntityID" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Customer ID">
										<b-form-input v-model="form.CustomerID" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Name">
										<b-form-input v-model="form.PersonName" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Phone Number">
										<b-form-input v-model="form.PhoneNumber" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="SME Name">
										<b-form-input v-model="form.SMEName" type="text" readonly />
									</b-form-group>
								</b-col>
								<b-col class="ml-3">
									<b-form-group class="mt-2" label="Province">
										<b-form-input v-model="form.Province" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="District">
										<b-form-input v-model="form.District" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Sub District">
										<b-form-input v-model="form.SubDistrict" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Village">
										<b-form-input v-model="form.Village" type="text" readonly />
									</b-form-group>
								</b-col>
							</b-row>
						</template>
					</ktv-accor>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex'
	export default {
		metaInfo: {
			title: "Customer Form",
		},
		data() {
			return {
				loading: true,
				form : {
					EntityId: "20714",
					"CustomerId": "1",
					"PersonName": "selasa111",
					"ListOfValueType": "object_commodity",
					"Value": "cocoatracenestle",
					"PhoneNumber": "8261",
					"EntityVillageID": null,
					"Village": null,
					"EntitySubDistrictID": null,
					"SubDistrict": null,
					"EntityDistrictID": null,
					"District": null,
					"EntityProvinceID": null,
					"Province": null
				}
			}
		},
		computed: {
			...mapGetters({
				opsiDisplay: "CUSTOMER/getOpsiDisplay",
				error: "CUSTOMER/getError",
			})
		},
		watch: {
			error(val) {
				if (val != null) {
					this.makeToast("Error", "danger", val.message)
				}
			},
		},
		async mounted() { 
			await this.FetchData()
		},
		methods: {
			...mapActions({
				clearData: "CUSTOMER/clearData",
				getDataDetail: "CUSTOMER/getDataDetail",
			}),
			async FetchData() {
				if (this.opsiDisplay === 'View' || this.opsiDisplay === 'Update') {
					const res = await this.getDataDetail()
					this.form = res
					this.loading = false
				}
				
			},
			backToList() {
				this.$router.back()
			},
		}
	}
</script>